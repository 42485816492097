import React from "react";

export default function OccupationList(props) {
    const {occupations, handleDelete, colorPattern} = props;

    // console.log(occupations)

    const getDate = (date) => {
        let newDate = date.split('/');
        let dayDate = new Date(newDate[1]+"/"+newDate[0]+"/"+newDate[2]);

        switch (dayDate.getDay()) {
            case 1: return <span className="pr-1">Lu</span>;
            case 2: return <span className="pr-1">Ma</span>;
            case 3: return <span className="pr-1">Me</span>;
            case 4: return <span className="pr-1">Je</span>;
            case 5: return <span className="pr-1">Ve</span>;
            case 6: return <span className="pr-1">Sa</span>;
            default: return <span className="pr-1">Di</span>
        }
    }

    const adaptDate = (date) => {
        let newDate = date.split('/');

        return newDate.map((value, key) => {
            return <span key={key}>{value+(key < 2 ? '/' : '')}</span>
        })
    }

    const buildOccupations = (days) => {
        let occupations = [];
        let occupationId = -1;
        let n = -1;

        for (let i = 0; i < days.length; i++) {
            if(occupationId !== days[i].occupation_id) {
                occupations.push([days[i].label, 0]);
                n++;
                occupationId = days[i].occupation_id;
            }
            occupations[n][1]++
        }

        return occupations.map((value, key) => {
            return (
                <div key={key} className="pr-2 mb-2" style={{width: ((100 / 14) * value[1])+'%', whiteSpace: 'nowrap'}}>
                    <div className="bg-gray-100 p-2">
                        <span className="pr-4">{ value[0] }</span>
                    </div>
                </div>
            )
        })
    }

    return (
        <div className="occupation-list__container">
            {
                occupations.map((value, key) => (
                    <div key={key} className="text-sm w-full mb-2">
                        <span className="p-2 flex items-center"><span className="occupation-list__color" style={{backgroundColor: colorPattern[key]}}>&nbsp;</span> {`${value.device_name} - ${value.sensor_name} ${value.min_date} au ${value.max_date}`}</span>
                        <div>
                            <div className="occupation-list__table mb-4">
                                {
                                    value.days.map((value, key) => (
                                        <span key={key} className="occupation-list__td">
                                            <span className="flex-wrap flex">
                                                {getDate(value.date)}
                                                {adaptDate(value.date)}
                                            </span>
                                            <span className="occupation-list__tooltip">{value.label}</span>
                                        </span>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-1 mr-2 flex">
                                { buildOccupations(value.days) }
                            </div>
                            <button onClick={(e) => { handleDelete(key) }}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-trash" viewBox="0 0 16 16">
                                    <path
                                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                    <path fillRule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                ))
            }
        </div>
    );
}