import axios from "axios";
import { API_URL } from './settings';


const client = axios.create({
    baseURL: API_URL
})

const getDevices = () => {
    return client.get('/devices').then(response => response.data);
}

const getOccupations = (device_serial, sensor_name, start_date) => {
    return client.get('/occupations', {
        params: {
            device_serial: device_serial,
            sensor_name: sensor_name,
            start_date: start_date
        }
      }).then(response => response.data);
}

const getMeasurements = (device_serial, sensor_name, start_date, freq) => {
    return client.get('/measurements', {
        params: {
            device_serial: device_serial,
            sensor_name: sensor_name,
            start_date: start_date,
            freq: freq
        }
      }).then(response => response.data);
}

export {
    getOccupations,
    getMeasurements,
    getDevices
}