import React, {useEffect} from "react";
import c3 from "c3/c3";
import "c3/c3.css"

const colorPattern = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];

export default function ChartHourly(props) {
    const {measurementsHourly} = props;

    useEffect(() => {
        let columns =  measurementsHourly.map((value, key) => {
            let column = [`${value.device_serial}-${value.start_date}-${value.sensor_name}`];
            for (let i = 0; i < value.measurements.length; i++) {
                column.push(value.measurements[i].value);
            }
            return column;
        })

        let category = [];
        for (let i = 0; i < measurementsHourly[0].measurements.length; i++) {
            category.push(measurementsHourly[0].measurements[i].key);
        }

        c3.generate({
            bindoto: "#chart",
            data: {
                type: "bar",
                columns: columns
            },
            axis: {
                x: {
                    type: 'category',
                    categories: category
                }
            },
            legend: {
                show: false
            },
            color: {
                pattern: colorPattern
            }
        })
    })

    return (
        <>
            <div id="chart">&nbsp;</div>
        </>
    );
}