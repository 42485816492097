import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';
import './index.css';
import './assets/scss/main.scss';
import {BrowserRouter as Router, Switch} from "react-router-dom";

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <Switch>
                <App />
            </Switch>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);