import React, {useEffect} from "react";
import c3 from "c3/c3";
import "c3/c3.css"

export default function ChartDaily(props) {
    const {handleClickHourly, measurements, colorPattern} = props;

    useEffect(() => {
        let data = []
        let days = {}
        let name = []
        let category = []

        for (let i = 0; i < measurements.length; i++) {
            category = measurements[i].category
            data.push(measurements[i].data)
            days = {
                ...days,
                [measurements[i].nameData]: measurements[i].days
            }
            name = {
                ...name,
                [measurements[i].nameData]: measurements[i].name
            }
        }

        c3.generate({
            bindto: '#chart',
            data: {
                type: "bar",
                onclick: function(d) {
                    let idColor = d.id.replace('data', '');
                    handleClickHourly(d, colorPattern[parseInt(idColor)-1])
                },
                columns: data,
                names: name
            },
            axis: {
                x: {
                    type: 'category',
                    categories: category
                }
            },
            legend: {
                show: false
            },
            tooltip: {
                format: {
                    name: function(name, ratio, id, index) {
                        return days[id][index];
                    }
                }
            },
            color: {
                pattern: colorPattern
            }
        });
    }, [measurements, handleClickHourly, colorPattern])

    return (
        <>
            <div id="chart" className="mb-4">&nbsp;</div>
        </>
    );
}
