import React, {useEffect, useState} from 'react';
import * as IscApi from "services/IscApi";
import {useLocation} from "react-router";

import ChartDaily from 'components/Charts/ChartDaily';
import ChartHourly from 'components/Charts/ChartHourly';
import Form from "../Form";
import OccupationList from "../OccupationList";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const App = () => {
    const [isHourly, setIsHourly] = useState(false)
    const [occupations, setOccupations] = useState([])
    const [measurements, setMeasurements] = useState([])
    const [measurementsHourly, setMeasurementsHourly] = useState({})
    const [hourlyColor, setHourlyColor] = useState("")

    let query = useQuery();
    let sensor_serial = query.get("sensor_serial")
    let sensor_name = query.get("sensor_name")
    let start_date = query.get("start_date")

    const colorPattern = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];

    const buildOccupations = (data) => {
        setOccupations([
            ...occupations,
            data
        ])
    }

    const buildMeasurements = (data) => {
        let newData = [`data${measurements.length+1}`];
        let newCategory = []
        let newDays = []

        for (let i = 0; i < data.measurements.length; i++) {
            newData.push(data.measurements[i].value)
            newCategory.push('Jour '+(i+1))
            newDays.push(data.measurements[i].key)
        }

        setMeasurements([
            ...measurements,
            {
                nameData: `data${measurements.length+1}`,
                name: `${data["sensor_name"]}-${data["device_serial"]}`,
                days: newDays,
                category: newCategory,
                data: newData
            }
        ])
    }

    useEffect(() => {
        async function fetchData() {
            let measurementsData = await IscApi.getMeasurements(sensor_serial, sensor_name, start_date)
            buildMeasurements(measurementsData)
            let occupationsData = await IscApi.getOccupations(sensor_serial, sensor_name, start_date)
            buildOccupations(occupationsData)
        }

        if(sensor_serial || sensor_name) {
            fetchData();
        }
    }, [sensor_serial, sensor_name])

    const handleClickHourly = async (d, color) => {
        let arrayMeasurements = measurements.filter((value) => { return value.days[d.index] !== undefined }).map((value, key) => {
            return  [value.days[d.index], value.name];
        })

        let measurementsHourlyData = [];
        for (let i = 0; i < arrayMeasurements.length; i++) {
            let serial = arrayMeasurements[i][1].split('-');
            let day = arrayMeasurements[i][0];
            measurementsHourlyData.push(await IscApi.getMeasurements(serial[1], serial[0], day, "hourly"))
        }
        setMeasurementsHourly(measurementsHourlyData)
        setHourlyColor(color)
        setIsHourly(true)
    }

    const addData = async (data) => {
        let measurementsDailyData = await IscApi.getMeasurements(data.mat, data.sensor, data.startDate, "daily")
        let occupationsData = await IscApi.getOccupations(data.mat, data.sensor, data.startDate)

        buildOccupations(occupationsData)
        buildMeasurements(measurementsDailyData)
    }

    const handleDelete = (key) => {
        setMeasurements(measurements.filter((value, id) => key !== id))
        setOccupations(occupations.filter((value, id) => key !== id))
    }

  return (
    <div className="h-full">
        <div className="flex justify-center h-full">
            <div className="chart__container container p-6">
                <header className="mb-6 pb-6 header__title">
                    { isHourly ? <button onClick={() => {
                        setIsHourly(false)
                    }} className="button">Retour</button>: null }
                </header>
                {
                    isHourly ?
                        <ChartHourly measurementsHourly={measurementsHourly} hourlyColor={hourlyColor} />
                        :
                        <ChartDaily handleClickHourly={handleClickHourly} measurements={measurements} colorPattern={colorPattern} />
                }
                <OccupationList occupations={occupations} handleDelete={handleDelete} colorPattern={colorPattern} />
                <Form handleSubmit={addData} start_date={start_date} sensor_name={sensor_name} sensor_serial={sensor_serial} />
            </div>
        </div>
        <footer className="fixed bottom-0 w-full text-right pb-2 pr-4 z-30">
            <span className="text-sm">Crafted by <a href="https://kern-it.be" target="_blank" className="hover:text-black hover:underline">KERN-IT</a></span>
        </footer>
    </div>
  );
}

export default App;
