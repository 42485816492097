import React, {useEffect, useState} from "react";
import * as IscApi from "services/IscApi";

export default function Form(props) {
    const {handleSubmit, start_date, sensor_name, sensor_serial} = props;

    const [state, setState] = useState({
        mat: sensor_serial ?? "",
        sensor: sensor_name ?? "CM1",
        startDate: start_date ?? ""
    })
    const [devicesList, setDevicesList] = useState([])

    const getDevicesFromAPI = async () => {
        const devices = await IscApi.getDevices();
        if (devices.success) {
            let devicesArray = devices.devices.map(value => {
                return [value.serial, value.name]
            })
            setDevicesList(devicesArray)
            // setState({
            //     ...state,
            //     mat: devices.devices[0].serial
            // })
        }
    }

    useEffect(() => {
        getDevicesFromAPI()
    }, [])

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        });
    }

    const getNow = () => {
        let date = new Date();
        let day = date.getDate() < 10 ? "0"+(date.getDate()) : date.getDate();
        let month = date.getMonth()+1 < 10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
        return `${date.getFullYear()}-${month}-${day}`
    }

    const getSelectedValue = () => {
        let mat = '';
        for (let i = 0; i < devicesList.length; i++) {
            if (devicesList[i][0] === state.mat) {
                mat = devicesList[i][0];
            }
        }
        return mat;
    }

    return (
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(state); }} className="flex justify-between mb-4">
            <div className="flex flex-col select__container">
                <label htmlFor="mat" className="text-sm mb-2">Mât</label>
                <select name="mat" id="mat" onChange={handleChange} className="select__input" value={getSelectedValue()}>
                    {
                        devicesList.map((value, key) => (
                            <option key={key} value={value[0]}>{value[1]}</option>
                        ))
                    }
                </select>
            </div>
            <div className="flex flex-col select__container">
                <label htmlFor="sensor" className="text-sm mb-2">Capteur</label>
                <select name="sensor" id="sensor" onChange={handleChange} className="select__input" value={state.sensor}>
                    <option value="CM1">CM1</option>
                    <option value="CM2">CM2</option>
                    <option value="CM3">CM3</option>
                    <option value="CM4">CM4</option>
                </select>
            </div>
            <div className="flex flex-col date__container">
                <label htmlFor="startDate" className="text-sm mb-2">Date</label>
                <input type="date" name="startDate" id="startDate" onChange={handleChange} max={getNow()} defaultValue={start_date} required className="date__input" />
            </div>
            <div className="flex items-end">
                <button type="submit" className="button">Ajouter</button>
            </div>
        </form>
    );
}